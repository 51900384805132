import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';
import { filter, map, delay, switchMap } from 'rxjs/operators';
import { CmsColModel, CmsShareInfoModel } from '@shared/models';
import { BaseComponent } from '../base/base.component';
import { CmsMapperService, WidgetsService } from '@core/services';

@Component({
  selector: 'pr-cms-col',
  templateUrl: './cms-col.component.html',
  styleUrls: ['./cms-col.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsColComponent extends BaseComponent
  implements OnInit, OnDestroy {
  @Input() shareInfo: CmsShareInfoModel;

  private _data: CmsColModel;
  @Input() set data(data: CmsColModel) {
    this._data = data;

    this.checkShareInfo();
    this.checkRecurringUpdate();
  }
  get data(): CmsColModel {
    return this._data;
  }
  activeSection$: Observable<string>;
  private subscriptions: Subscription[] = [];

  constructor(
    activatedRoute: ActivatedRoute,
    private cmsMapper: CmsMapperService,
    private widgetsService: WidgetsService,
    private cdr: ChangeDetectorRef
  ) {
    super();
    this.activeSection$ = activatedRoute.queryParams.pipe(
      filter((x) => x.section != null),
      map((x) => x.section as string)
    );
  }

  ngOnInit(): void {}

  isActiveSection(section: string) {
    return (
      this.data.widgetIds?.length > 0 && this.data.widgetIds[0] === section
    );
  }

  private checkShareInfo() {
    if (this.shareInfo == null && this._data.isPanel) {
      const shareInfo = this.cmsMapper.getWidgetShareInfoFromCol(this._data)[0];
      this.shareInfo = shareInfo;
    }
  }

  private checkRecurringUpdate() {
    this._data.widgetIds.forEach((widgetId) => {
      this.subscriptions.forEach((s) => s.unsubscribe());
      this.subscriptions = [];

      this.subscriptions.push(
        of(true)
          .pipe(
            delay(this.shareInfo.refreshInterval),
            switchMap(() => this.widgetsService.getWidgetById(widgetId))
          )
          .subscribe((widgetData) => {
            const newCol = this.cmsMapper.replaceWidgetInCol(
              this.data,
              widgetData
            );
            this.data = {
              ...newCol,
            };
            this.cdr.markForCheck();
          })
      );
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
