import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { CommonAppConfig } from '@core/services';

@Component({
  selector: 'pr-cms-content-prahapracujewidget',
  templateUrl: './cms-content-prahapracujewidget.component.html',
  styleUrls: ['./cms-content-prahapracujewidget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsContentPrahaPracujeWidgetComponent implements OnInit {
  @Input() header: string;
  @Input() note: string;

  constructor() {}

  ngOnInit(): void {}
}
