import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CmsHeaderSliderItemModel } from '@shared/models';
import { BaseComponent } from '../base/base.component';
import { CommonAppConfig } from '@core/services';
import { fadeInOutAnimation } from '@shared/animations';

@Component({
  selector: 'pr-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOutAnimation],
})
export class CarouselComponent extends BaseComponent implements OnInit {
  private _data: CmsHeaderSliderItemModel[];
  @Input() set data(data: CmsHeaderSliderItemModel[]) {
    this._data = data;
    this.restartCarousel();
  }
  get data() {
    return this._data;
  }
  cmsUrl: string;
  intervalSubscription$: Subscription;
  activeIndex = 0;

  constructor(config: CommonAppConfig, private cdr: ChangeDetectorRef) {
    super();
    this.cmsUrl = config.get().cmsUrl;
  }

  ngOnInit(): void {}

  setActiveClicked(i: number) {
    this.stopCarousel();
    this.activeIndex = i;
    this.startCarousel();
  }

  private restartCarousel() {
    this.stopCarousel();
    this.startCarousel();
  }

  private startCarousel() {
    this.intervalSubscription$ = this.intervalSubscription$ = interval(20000)
      .pipe(takeUntil(this.baseSubject))
      .subscribe(() => {
        this.activeIndex < 2 ? (this.activeIndex += 1) : (this.activeIndex = 0);
        this.cdr.markForCheck();
      });
  }

  private stopCarousel() {
    this.intervalSubscription$?.unsubscribe();
  }
}
