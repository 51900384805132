import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map, startWith, distinctUntilChanged } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ScrollService } from '@core/services';

@Component({
  selector: 'pr-home-menu',
  templateUrl: './home-menu.component.html',
  styleUrls: ['./home-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeMenuComponent implements OnInit {
  url$: Observable<string>;

  constructor(private router: Router) {
    this.url$ = router.events.pipe(
      startWith({ url: router.url } as NavigationEnd),
      filter((event: any) => {
        return event.url != null;
      }),
      map((e: NavigationEnd) => {
        return e.url;
      }),
      distinctUntilChanged()
    );
  }

  ngOnInit(): void {}

  toNaturalEnvironment() {
    this.router.navigate(['/natural-environment']);
  }

  toTransport() {
    this.router.navigate(['/transport']);
  }

  toTourism() {
    this.router.navigate(['/tourism']);
  }

  toCars() {
    this.router.navigate(['/cars']);
  }

  toHealth() {
    this.router.navigate(['/health']);
  }

  toMobility() {
    this.router.navigate(['/mobility']);
  }

  toHabitation() {
    this.router.navigate(['/housing']);
  }

  toRecycling() {
    this.router.navigate(['/recycling']);
  }

  toSecurity() {
    this.router.navigate(['/security']);
  }

  toCitymanagement() {
    this.router.navigate(['/citymanagement']);
  }

  toCuriosity() {
    this.router.navigate(['/curiosity']);
  }

  toEconomy() {
    this.router.navigate(['/economy']);
  }

  toEducation() {
    this.router.navigate(['/education']);
  }
}
