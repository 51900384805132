import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import {
  CmsContentModel,
  WidgetModel,
  CmsShareInfoModel,
} from '@shared/models';

@Component({
  selector: 'pr-cms-content',
  templateUrl: './cms-content.component.html',
  styleUrls: ['./cms-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsContentComponent implements OnInit {
  @Input() shareInfo: CmsShareInfoModel;
  @Input() data: CmsContentModel;
  constructor() {}

  ngOnInit(): void {}
}
