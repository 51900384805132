import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
} from '@angular/core';
import { CmsRowModel, CmsShareInfoModel } from '@shared/models';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'pr-cms-row',
  templateUrl: './cms-row.component.html',
  styleUrls: ['./cms-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsRowComponent extends BaseComponent implements OnInit {
  @Input() shareInfo: CmsShareInfoModel;
  @Input() data: CmsRowModel;

  constructor() {
    super();
  }

  ngOnInit(): void {}
}
