import {
  Component,
  ChangeDetectionStrategy,
  Input,
  AfterViewInit,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { WidgetModel, MapPraguePoiDataModel } from '@shared/models/widgets';
import { CmsWidgetSettingsModel } from '@shared/models';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '../base';
import { takeUntil } from 'rxjs/operators';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'pr-map-prague-poi',
  templateUrl: './map-prague-poi.component.html',
  styleUrls: ['./map-prague-poi.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapPraguePoiComponent extends BaseComponent
  implements AfterViewInit {
  @ViewChildren('t') tooltip: NgbTooltip;
  @Input() data: WidgetModel<MapPraguePoiDataModel[]>;
  @Input() settings: CmsWidgetSettingsModel;
  coords: {
    x: number;
    y: number;
    title: string;
    description: string;
    color: string;
  }[] = [];

  @ViewChild('map') map: ElementRef;
  showFirstTooltip = true;
  // map sizes
  mapHeight: number;
  aspectRatio = 0.8206;
  mapWidth: number;

  // pointer
  mapLngFrom = 14.1995;
  mapLngTo = 14.73172;
  mapLngAxis = (this.mapLngFrom - this.mapLngTo) * -1;

  mapLatFrom = 50.19962;
  mapLatTo = 49.9197;
  mapLatAxis = (this.mapLatTo - this.mapLatFrom) * -1;
  observer: MutationObserver;

  constructor(private cdr: ChangeDetectorRef) {
    super();
    fromEvent(window, 'resize')
      .pipe(takeUntil(this.baseSubject))
      .subscribe(() => {
        this.initCoords(
          this.map.nativeElement.offsetHeight,
          this.map.nativeElement.offsetWidth
        );
        this.cdr.markForCheck();
      });
  }

  ngAfterViewInit(): void {
    this.initCoords(
      this.map.nativeElement.offsetHeight === 0
        ? this.map.nativeElement.offsetWidth * this.aspectRatio
        : this.map.nativeElement.offsetWidth,
      this.map.nativeElement.offsetWidth
    );
    this.cdr.markForCheck();
  }

  getMarkerPosition(
    pointLng: number,
    pointLat: number,
    title: string,
    description: string,
    color: string
  ): {
    x: number;
    y: number;
    title: string;
    description: string;
    color: string;
  } {
    const pointOnLng = pointLng - this.mapLngFrom;
    const pointOnLat = pointLat - this.mapLatTo;

    const pointOnMapLng = pointOnLng / this.mapLngAxis;
    const pointOnMapLat = pointOnLat / this.mapLatAxis;

    const pointX = pointOnMapLng * this.mapWidth;
    const pointY = pointOnMapLat * this.mapHeight;

    const pointOnMapX = pointX;
    const pointOnMapY = this.mapHeight - pointY;

    return { x: pointOnMapX, y: pointOnMapY, title, description, color };
  }

  openTooltip(
    tooltip: NgbTooltip,
    title: string,
    description: string,
    color: string
  ) {
    if (!tooltip.isOpen() && !this.settings.hideTooltips) {
      tooltip.open({ title, description, color });
    }
  }

  closeTooltip(tooltip: NgbTooltip) {
    if (tooltip.isOpen()) {
      tooltip.close();
    }
  }

  private initCoords(mapHeight: number, mapWidth: number) {
    this.mapHeight = mapHeight;
    this.mapWidth = mapWidth;

    this.coords = this.data.data.map((item) =>
      this.getMarkerPosition(
        item.lng || item.lon, // api wrong behavior
        item.lat,
        item.title,
        item.description,
        item.labelColor
      )
    );
  }
}
