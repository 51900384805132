import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { CmsTextModel } from '@shared/models';

@Component({
  selector: 'pr-cms-content-text',
  templateUrl: './cms-content-text.component.html',
  styleUrls: ['./cms-content-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsContentTextComponent implements OnInit {
  @Input() data: CmsTextModel;

  constructor() {}

  ngOnInit(): void {}
}
