import { BaseComponent } from './base.component';

export class WidgetWithLegendComponent extends BaseComponent {
  legendHeight: number;

  public formatNumber(num: number) {
    const integerPart = Math.floor(num);
    if (num === integerPart) {
      return integerPart.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
    }
    else {
      const decimalPart = (num - integerPart)
        .toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 1
        })
        .substring(2);
      return integerPart.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') + ',' + decimalPart;
    }
  }
}
