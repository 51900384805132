import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import * as directives from './directives';
import * as components from './components';
import * as pipes from './pipes';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

const IMPORTS = [FormsModule, ReactiveFormsModule, NgxChartsModule, NgbModule];

const DIRECTIVES = [
  directives.StickyDirective,
  directives.ScrollRevealDirective,
  directives.ShowTooltipDirective,
];

const COMPONENTS = [
  components.FullWidthBannerComponent,
  components.ChartBarVerticalComponent,
  components.ChartBarVerticalDetailComponent,
  components.ChartBarHorizontalComponent,
  components.ChartBarHorizontalDetailComponent,
  components.ChartBarVerticalStackedComponent,
  components.ChartBarVerticalStackedDetailComponent,
  components.VisualNumberComponent,
  components.ChartLineComponent,
  components.ChartLineDetailComponent,
  components.ChartPieComponent,
  components.ChartPieAdvancedComponent,
  components.ChartGaugeComponent,
  components.TableComponent,
  components.MapPragueDistrictComponent,
  components.MapPraguePoiComponent,
  components.CmsPageComponent,
  components.CmsContentWidgetComponent,
  components.CmsContentTextComponent,
  components.CmsContentImageComponent,
  components.CmsContentPrahaPracujeWidgetComponent,
  components.PrahaPracujeBtnComponent,
  components.CmsContentComponent,
  components.MenuStickyComponent,
  components.HomeMenuComponent,
  components.MenuComponent,
  components.MenuItemComponent,
  components.CarouselComponent,
  components.CmsContentShareInfoComponent,
  components.CmsRowComponent,
  components.CmsColComponent,
  components.UnavailableWidgetComponent,
  components.LoadingComponent,
  components.LiveDataIconComponent,
];

const PIPES = [pipes.LocalizedTextPipe, pipes.SectionsNamesPipe];

const SERVICES = [];

const EXPORTS = [
  FormsModule,
  ReactiveFormsModule,
  NgxChartsModule,
  NgbModule,
  ...DIRECTIVES,
];

@NgModule({
  imports: [...IMPORTS],
  declarations: [...DIRECTIVES, ...COMPONENTS, ...PIPES],
  exports: [...EXPORTS, ...COMPONENTS, ...PIPES],
  providers: [...SERVICES],
})
export class SharedModule {}
