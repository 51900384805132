import {
  Directive,
  ChangeDetectorRef,
  AfterViewInit,
  ElementRef,
  HostBinding,
  Input,
} from '@angular/core';
import { fromEvent, merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@shared/components';
import { Router } from '@angular/router';

@Directive({
  selector: '[prScrollReveal]',
  exportAs: 'scrollRevealDirective',
})
export class ScrollRevealDirective extends BaseComponent
  implements AfterViewInit {
  @HostBinding('class.is-in-view')
  isInView = false;
  @HostBinding('class.scroll-reveal')
  scrollReveal = true;

  wasInView = false;

  @Input() isChart = false;
  chartIsInView = false;

  // tslint:disable-next-line: no-input-rename
  @Input('scrollRevealChartHeight') chartHeight: number;

  constructor(private cdr: ChangeDetectorRef, private el: ElementRef) {
    super();
    merge(fromEvent(window, 'scroll'), fromEvent(window, 'resize'))
      .pipe(takeUntil(this.baseSubject))
      .subscribe(() => this.determineScroll());
  }

  ngAfterViewInit(): void {
    this.determineScroll();
  }

  private determineScroll() {
    const elementHeight = this.el.nativeElement.offsetHeight;
    const windowHeight = window.innerHeight;
    const elementFromTop = this.el.nativeElement.getBoundingClientRect().top;
    const revealOffset = 50;

    if (!this.isChart) {
      if (revealOffset + elementFromTop < windowHeight) {
        this.isInView = true;
      }
    } else if (this.isChart && this.chartHeight != null) {
      this.scrollReveal = false;
      if (this.chartHeight / 2 + elementFromTop < windowHeight) {
        this.chartIsInView = true;
      }
    }

    this.cdr.detectChanges();
  }
}
