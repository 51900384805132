import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { CommonAppConfig } from '@core/services';

@Component({
  selector: 'pr-cms-content-image',
  templateUrl: './cms-content-image.component.html',
  styleUrls: ['./cms-content-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsContentImageComponent implements OnInit {
  @Input() data: string;

  cmsUrl: string;
  constructor(config: CommonAppConfig) {
    this.cmsUrl = config.get().cmsUrl;
  }

  ngOnInit(): void {}
}
