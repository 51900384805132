import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ElementRef,
  AfterViewInit,
  HostBinding,
  ViewChild,
  forwardRef,
} from '@angular/core';
import {
  WidgetModel,
  ChartNameValueModel,
  ChartPieDataModel,
} from '@shared/models/widgets';
import { BaseComponent } from '../base/base.component';
import { CmsWidgetSettingsModel } from '@shared/models';
import {
  PieChartComponent,
  LegendComponent,
  ColorHelper,
} from '@swimlane/ngx-charts';
import { WidgetWithLegendComponent } from '../base';

@Component({
  selector: 'pr-chart-pie-advanced',
  templateUrl: './chart-pie-advanced.component.html',
  styleUrls: ['./chart-pie-advanced.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: WidgetWithLegendComponent,
      useExisting: forwardRef(() => ChartPieAdvancedComponent),
    },
  ],
})
export class ChartPieAdvancedComponent extends WidgetWithLegendComponent
  implements OnInit, AfterViewInit {
  @ViewChild(PieChartComponent) chart: PieChartComponent;
  @ViewChild('legend', { read: ElementRef, static: true })
  legendComponent: ElementRef;
  @Input() settings: CmsWidgetSettingsModel;
  @HostBinding('style.height.px')
  get height() {
    return this.settings?.chartHeight;
  }
  // tslint:disable-next-line:variable-name
  private _data: WidgetModel<ChartPieDataModel>;
  @Input() set data(data: WidgetModel<ChartPieDataModel>) {
    this._data = data;

    this.legendData = data.data.data.map((d) => d.name);
    this.legendColors = new ColorHelper(
      this.settings.colorScheme,
      'ordinal',
      this.legendData,
      null
    );
  }
  get data(): WidgetModel<ChartPieDataModel> {
    return this._data;
  }

  legendData;
  legendColors;
  view: any[] = undefined;

  // options
  gradient = true;
  showLegend = true;
  showLabels = false;
  isDoughnut = false;
  tooltipDisabled = false;
  showDataLabel = false;

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA'],
  };
  get legendHeight(): number {
    return (
      this.legendComponent?.nativeElement.offsetHeight ||
      this.settings.legendMinHeight
    );
  }

  constructor(private el: ElementRef) {
    super();
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  onSelect(data): void {}

  onActivate(data): void {}

  onDeactivate(data): void {}
  legendActivate(data) {
    this.chart.onActivate(data);
  }

  legendDeactivate(data) {
    this.chart.onDeactivate(data);
  }
}
