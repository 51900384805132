import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { CommonAppConfig } from '@core/services';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'pr-prahapracuje-btn',
  templateUrl: './prahapracuje-btn.component.html',
  styleUrls: ['./prahapracuje-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrahaPracujeBtnComponent extends BaseComponent implements OnInit {
  prahaPracujeUrl: string;

  constructor(config: CommonAppConfig, private cdr: ChangeDetectorRef) {
    super();
    this.prahaPracujeUrl = config.get().prahaPracujeUrl;
  }

  ngOnInit(): void {}
}
