import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';

@Component({
  selector: 'pr-unavailable-widget',
  templateUrl: './unavailable-widget.component.html',
  styleUrls: ['./unavailable-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnavailableWidgetComponent implements OnInit {
  @Input() category: string;

  constructor() {}

  ngOnInit(): void {}
}
