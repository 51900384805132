import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import { fadeInOutAnimation } from '@shared/animations';
import { CmsShareInfoModel } from '@shared/models';

@Component({
  selector: 'pr-cms-content-share-info',
  templateUrl: './cms-content-share-info.component.html',
  styleUrls: ['./cms-content-share-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOutAnimation],
})
export class CmsContentShareInfoComponent implements OnInit {
  @Input() data: CmsShareInfoModel;
  coppied = false;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {}

  copyToClipboard(widgetId: string) {
    const el = document.createElement('textarea');
    el.value = this.getWidgetUrl(widgetId);
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.coppied = true;

    setTimeout(() => {
      this.coppied = false;
      this.cdr.detectChanges();
    }, 3000);
  }

  private getWidgetUrl(widgetId: string) {
    let currentURL = window.location.href;
    const questionMarkIndex = currentURL.indexOf('?');
    if (questionMarkIndex >= 0) {
      currentURL = currentURL.substring(0, questionMarkIndex);
    }
    return currentURL + '?section=' + widgetId;
  }
}
