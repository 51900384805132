import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChildren,
  ElementRef,
  QueryList,
  AfterViewInit,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { takeUntil, map, filter } from 'rxjs/operators';
import { CmsPageModel } from '@shared/models';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'pr-cms-page',
  templateUrl: './cms-page.component.html',
  styleUrls: ['./cms-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsPageComponent extends BaseComponent implements OnInit {
  @ViewChildren('sections') sections: QueryList<ElementRef> = new QueryList<
    ElementRef
  >();
  @Input() data: CmsPageModel;

  @Output() scrolledToSection: EventEmitter<string> = new EventEmitter();

  constructor() {
    super();
    fromEvent(window, 'scroll')
      .pipe(
        map(() => this.handleActiveSubmenu()),
        filter((x) => x != null),
        takeUntil(this.baseSubject)
      )
      .subscribe((x) => {
        this.scrolledToSection.emit(x.id);
      });
  }

  ngOnInit(): void {}

  private handleActiveSubmenu() {
    const stickyMenuHeight = 108;
    const offsetTop = window.pageYOffset;
    const nativeElements = this.sections.map(
      (section) => section.nativeElement
    );
    const scrolledToEls = nativeElements.filter((el) => {
      const isScrolled = el.offsetTop < offsetTop + stickyMenuHeight;
      return isScrolled;
    });
    return scrolledToEls.slice(-1).pop();
  }
}
