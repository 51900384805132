import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  ViewChild,
  HostBinding,
} from '@angular/core';
import { WidgetModel, CmsWidgetSettingsModel } from '@shared/models';
import { WidgetWithLegendComponent } from '../base';

@Component({
  selector: 'pr-cms-content-widget',
  templateUrl: './cms-content-widget.component.html',
  styleUrls: ['./cms-content-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsContentWidgetComponent implements OnInit, OnDestroy {
  @Input() settings: CmsWidgetSettingsModel;
  @Input() data: WidgetModel<any>;
  
  @ViewChild(WidgetWithLegendComponent) widget: WidgetWithLegendComponent;

  @HostBinding('style.paddingBottom.px')
  get paddingBottom(): number {
    return this.widget?.legendHeight ?? 0;
  }
  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
