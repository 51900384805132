import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ElementRef,
  AfterViewInit,
  HostBinding,
  ViewChild,
  forwardRef,
  ChangeDetectorRef,
  Renderer2,
} from '@angular/core';
import { WidgetModel, ChartDataModel } from '@shared/models/widgets';
import { BaseComponent } from '../base/base.component';
import { CmsWidgetSettingsModel } from '@shared/models';
import {
  LegendComponent,
  BarVerticalStackedComponent,
  ColorHelper,
} from '@swimlane/ngx-charts';
import { WidgetWithLegendComponent } from '../base';

@Component({
  selector: 'pr-chart-bar-vertical-stacked',
  templateUrl: './chart-bar-vertical-stacked.component.html',
  styleUrls: ['./chart-bar-vertical-stacked.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: WidgetWithLegendComponent,
      useExisting: forwardRef(() => ChartBarVerticalStackedComponent),
    },
  ],
})
export class ChartBarVerticalStackedComponent extends WidgetWithLegendComponent
  implements OnInit, AfterViewInit {
  @ViewChild(BarVerticalStackedComponent) chart: BarVerticalStackedComponent;
  @ViewChild('legend', { read: ElementRef, static: true })
  legendComponent: ElementRef;
  isDetailVisible: boolean;
  @Input() settings: CmsWidgetSettingsModel;
  @HostBinding('style.height.px')
  get height() {
    return this.settings?.chartHeight;
  }
  // tslint:disable-next-line:variable-name
  private _data: WidgetModel<ChartDataModel>;
  @Input() set data(data: WidgetModel<ChartDataModel>) {
    this._data = data;

    this.legendData = data.data.series.map((d) => d.name);
    this.legendColors = new ColorHelper(
      this.settings.colorScheme,
      'ordinal',
      this.legendData,
      null
    );
  }
  get data(): WidgetModel<ChartDataModel> {
    return this._data;
  }

  legendData;
  legendColors;
  view: any[] = undefined;

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  tooltipDisabled = false;
  showDataLabel = false;
  showXAxisLabel = true;
  showYAxisLabel = true;
  animations = true;

  colorScheme = {
    domain: ['#5AA454', '#C7B42C', '#AAAAAA'],
  };
  get legendHeight(): number {
    return (
      this.legendComponent?.nativeElement.offsetHeight ||
      this.settings.legendMinHeight
    );
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2) {
    super();
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  onSelect(event) {}
  legendActivate(data) {
    this.chart.onActivate(null, data);
  }

  legendDeactivate(data) {
    this.chart.onDeactivate(null, data);
  }

  showChartInPopup(){
    this.isDetailVisible = true;
    this.cdr.markForCheck();
    this.renderer.addClass(document.body, 'hide-scroll');
  }

  closeChartDetail(){
    this.isDetailVisible = false;
    this.cdr.markForCheck();
    this.renderer.removeClass(document.body, 'hide-scroll');
  }
}
