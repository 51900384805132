import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
} from '@angular/core';
import { WidgetModel, TableDataModel } from '@shared/models/widgets';
import { CmsWidgetSettingsModel } from '@shared/models';

@Component({
  selector: 'pr-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent implements OnInit {
  @Input() settings: CmsWidgetSettingsModel;
  @HostBinding('style.height.px')
  get height() {
    return this.settings?.chartHeight - (this.settings.marginBottom ?? 0);
  }
  @Input() data: WidgetModel<TableDataModel>;

  constructor() {}

  ngOnInit() {}

  public formatValue(value: any) {
    if (typeof value === 'number' && Math.round(value) === value) {
      return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
    }
    else {
      return value;
    }
  }
}
