import { 
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'pr-live-data-icon',
  templateUrl: './live-data-icon.component.html',
  styleUrls: ['./live-data-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LiveDataIconComponent implements OnInit {
  @Input() refreshInterval: number;

  tooltip: string;

  constructor() {
  }

  ngOnInit(): void {
    this.tooltip = `Data se živě aktualizují z&nbsp;Datové platformy Golemio`;
    if (this.refreshInterval >= 1 * 60 * 60 * 1000) {
      this.tooltip = `Data se jednou za hodinu aktualizují z&nbsp;Datové platformy Golemio`;
    }
    if (this.refreshInterval >= 24 * 60 * 60 * 1000) {
      this.tooltip = `Data se jednou denně aktualizují z&nbsp;Datové platformy Golemio`;
    }
    if (this.refreshInterval >= 7 * 24 * 60 * 60 * 1000) {
      this.tooltip = `Data se jednou týdně aktualizují z&nbsp;Datové platformy Golemio`;
    }
  }
}
