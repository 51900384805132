import {
  Directive,
  HostBinding,
  AfterViewInit,
  ChangeDetectorRef,
  Input,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { BaseComponent } from '@shared/components';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[prSticky]',
})
export class StickyDirective extends BaseComponent implements AfterViewInit {
  @HostBinding('class.sticky')
  isSticky: boolean;

  // tslint:disable-next-line: no-input-rename
  @Input('prStickyOffsetTop') offsetTop: number;

  constructor(private cdr: ChangeDetectorRef) {
    super();
    fromEvent(window, 'scroll')
      .pipe(takeUntil(this.baseSubject))
      .subscribe(() => this.determineScroll());
  }

  ngAfterViewInit(): void {
    this.determineScroll();
  }

  private determineScroll() {
    if (this.offsetTop != null) {
      const scrollDistance = window.pageYOffset;
      this.isSticky = scrollDistance > this.offsetTop;
      this.cdr.detectChanges();
    }
  }
}
