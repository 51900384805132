import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ElementRef,
  AfterViewInit,
  HostBinding,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { WidgetModel, ChartGaugeDataModel } from '@shared/models/widgets';
import { BaseComponent } from '../base/base.component';
import { CmsWidgetSettingsModel } from '@shared/models';
import {
  LegendComponent,
  GaugeComponent,
  ColorHelper,
} from '@swimlane/ngx-charts';
import { WidgetWithLegendComponent } from '../base';

@Component({
  selector: 'pr-chart-gauge',
  templateUrl: './chart-gauge.component.html',
  styleUrls: ['./chart-gauge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: WidgetWithLegendComponent,
      useExisting: forwardRef(() => ChartGaugeComponent),
    },
  ],
})
export class ChartGaugeComponent extends WidgetWithLegendComponent
  implements OnInit, AfterViewInit {
  @ViewChild(GaugeComponent) chart: GaugeComponent;
  @ViewChild('legend', { read: ElementRef, static: true })
  legendComponent: ElementRef;
  @Input() settings: CmsWidgetSettingsModel;
  @HostBinding('style.height.px')
  get height() {
    return this.settings?.chartHeight;
  }
  // tslint:disable-next-line:variable-name
  private _data: WidgetModel<ChartGaugeDataModel>;
  @Input() set data(data: WidgetModel<ChartGaugeDataModel>) {
    this._data = data;

    this.legendData = data.data.data.map((d) => d.name);
    this.legendColors = new ColorHelper(
      this.settings.colorScheme,
      'ordinal',
      this.legendData,
      null
    );
  }
  get data(): WidgetModel<ChartGaugeDataModel> {
    return this._data;
  }

  legendData;
  legendColors;
  view: any[] = undefined;

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C'],
  };
  get legendHeight(): number {
    return (
      this.legendComponent?.nativeElement.offsetHeight ||
      this.settings.legendMinHeight
    );
  }

  constructor(private el: ElementRef) {
    super();
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  onSelect(event) {}
  legendActivate(data) {
    this.chart.onActivate(data);
  }

  legendDeactivate(data) {
    this.chart.onDeactivate(data);
  }
}
