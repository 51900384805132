import {
  Component,
  ChangeDetectionStrategy,
  Input,
  QueryList,
  ElementRef,
  ViewChildren,
  AfterViewInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { CmsPageType, CmsLocalizedTextModel } from '@shared/models/cms';
import { BaseComponent } from '../base';
import { ScrollService } from '@core/services';

@Component({
  selector: 'pr-menu-sticky',
  templateUrl: './menu-sticky.component.html',
  styleUrls: ['./menu-sticky.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuStickyComponent extends BaseComponent
  implements AfterViewInit {
  @Input() offsetTop: number;
  @Input() activeMenu: string;
  @Input() activeSubmenu: string;

  subMenuOffset: number;

  @Input() submenus: { sectionName: CmsLocalizedTextModel; url: string }[] = [];

  @ViewChildren('navigationItem') navigationItems: QueryList<ElementRef>;
  @ViewChild('logo') logo: ElementRef;
  @ViewChild('navigation') navigation: ElementRef;

  menuHomeActive = false;

  constructor(private router: Router, private scrollService: ScrollService) {
    super();
  }

  ngAfterViewInit(): void {
    const navigationItemsWidthSeparate = this.navigationItems.map((x) => {
      return x.nativeElement.offsetWidth;
    });
    const sumFce = (accumulator, currentValue) => accumulator + currentValue;
    const navigationItemsWidth = navigationItemsWidthSeparate.reduce(sumFce);
    const logoWidth = this.logo.nativeElement.offsetWidth;
    const navigationWidth = this.navigation.nativeElement.offsetWidth;
    this.subMenuOffset =
      navigationWidth + logoWidth - navigationItemsWidth - 80;
  }

  navigateByKey(type: CmsPageType | '') {
    this.router.navigate(['/' + type]).then(() => {
      this.scrollService.scrollToSection(type + '-heading', -30);
    });
  }

  scrollToSection(section: string) {
    this.scrollService.scrollToSection(section);
  }
}
