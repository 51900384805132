import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ElementRef,
} from '@angular/core';

@Component({
  selector: 'pr-full-width-banner',
  templateUrl: './full-width-banner.component.html',
  styleUrls: ['./full-width-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullWidthBannerComponent implements OnInit {
  @Input() size = null;

  get offsetTop() {
    if (this.el.nativeElement != null) {
      const marginTop = parseInt(
        window
          .getComputedStyle(this.el.nativeElement)
          .marginTop.replace('px', ''),
        0
      );
      const offsetTop = this.el.nativeElement.offsetTop;
      return offsetTop - marginTop;
    }
    return null;
  }

  constructor(private el: ElementRef) {}

  ngOnInit(): void {}
}
