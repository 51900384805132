import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ElementRef,
  AfterViewInit,
  HostBinding,
  ViewChild,
  forwardRef,
  Renderer2,
  ChangeDetectorRef,
} from '@angular/core';
import { WidgetModel, ChartDataModel } from '@shared/models/widgets';
import { CmsWidgetSettingsModel } from '@shared/models';
import {
  ColorHelper,
  LegendComponent,
  LineChartComponent,
} from '@swimlane/ngx-charts';
import { WidgetWithLegendComponent } from '../base';
import { StateService } from '@core/services';
import { Observable } from 'rxjs';
import { StateModel } from '@shared/models/common';

@Component({
  selector: 'pr-chart-line',
  templateUrl: './chart-line.component.html',
  styleUrls: ['./chart-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: WidgetWithLegendComponent,
      useExisting: forwardRef(() => ChartLineComponent),
    },
  ],
})
export class ChartLineComponent extends WidgetWithLegendComponent
  implements OnInit, AfterViewInit {
  @ViewChild(LineChartComponent) chart: LineChartComponent;
  @ViewChild('legend', { read: ElementRef, static: true })
  legendComponent: ElementRef;
  @Input() settings: CmsWidgetSettingsModel;
  @HostBinding('style.height.px')
  get height() {
    const height = this.settings?.chartHeight;
    return height;
  }
  private _data: WidgetModel<ChartDataModel>;
  state$: Observable<StateModel>;
  isDetailVisible: boolean;
  @Input() set data(data: WidgetModel<ChartDataModel>) {
    this._data = data;
    this.legendData = data.data.series.map((d) => d.name);
    this.legendColors = new ColorHelper(
      this.settings.colorScheme,
      'ordinal',
      this.legendData,
      null
    );
  }
  get data(): WidgetModel<ChartDataModel> {
    return this._data;
  }
  get legendHeight(): number {
    return (
      this.legendComponent?.nativeElement.offsetHeight ||
      this.settings.legendMinHeight
    );
  }

  legendData;
  legendColors;
  view: any[] = undefined;

  // options
  legend: boolean = true;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  timeline: boolean = false;
  showTitle = true;

  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5'],
  };

  chartNames: string[];
  colors: ColorHelper;

  constructor(
    private el: ElementRef,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private stateService: StateService) {
    super();
    this.state$ = this.stateService.state$;
  }

  ngOnInit() {
    this.chartNames = this.data.data.series.map((d) => d.name);
    this.colors = new ColorHelper(
      this.settings.colorScheme,
      'ordinal',
      this.chartNames,
      this.settings.colorScheme
    );
  }

  ngAfterViewInit() {}

  onSelect(data): void {}

  onActivate(data): void {}

  onDeactivate(data): void {}

  legendSelect(data): void {}

  legendActivate(data) {
    this.chart.onActivate(data);
  }

  legendDeactivate(data) {
    this.chart.onDeactivate(data);
  }

  showChartInPopup(){
    this.isDetailVisible = true;
    this.cdr.markForCheck();
    this.renderer.addClass(document.body, 'hide-scroll');
  }

  closeChartDetail(){
    this.isDetailVisible = false;
    this.cdr.markForCheck();
    this.renderer.removeClass(document.body, 'hide-scroll');
  }
}
