import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
} from '@angular/core';
import {
  WidgetModel,
  MapPragueDistrictDataModel,
} from '@shared/models/widgets';
import { CmsWidgetSettingsModel } from '@shared/models';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { LocalizedTextPipe } from '@shared/pipes';

@Component({
  selector: 'pr-map-prague-district',
  templateUrl: './map-prague-district.component.html',
  styleUrls: ['./map-prague-district.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapPragueDistrictComponent implements OnInit {
  @Input() data: WidgetModel<MapPragueDistrictDataModel[]>;
  @Input() settings: CmsWidgetSettingsModel;
  @HostBinding('style.height.px')
  get height() {
    return this.settings?.chartHeight - (this.settings?.marginBottom ?? 0);
  }

  get districtIds(): string[] {
    return this.data?.data.map((x) => x.districtId);
  }

  constructor(private localizedTextPipe: LocalizedTextPipe) {}

  ngOnInit() {}

  getTooltipTitle(districtId: string): string {
    const item = this.data.data.find((x) => x.districtId === districtId);
    if (item == null) {
      return null;
    }
    return (
      item.title +
      ' - ' +
      item.value +
      ' ' +
      this.localizedTextPipe.transform(item.unit)
    );
  }

  getOpacity(districtId: string): number {
    const values: number[] = this.data.data.map((x) => x.value);
    const max = Math.max(...values);
    const min = Math.min(...values);

    const districtValue = this.data.data.find(
      (x) => x.districtId === districtId
    )?.value;

    if (districtValue == null) {
      return 0.15;
    }

    const resultInPercent = this.scaleBetween(districtValue, 0.15, 1, min, max);

    return resultInPercent;
  }

  private scaleBetween(
    unscaledNum: number,
    minAllowed: number,
    maxAllowed: number,
    min: number,
    max: number
  ) {
    return (
      ((maxAllowed - minAllowed) * (unscaledNum - min)) / (max - min) +
      minAllowed
    );
  }

  openTooltip(tooltip: NgbTooltip, title: string) {
    if (!tooltip.isOpen()) {
      tooltip.open({ title });
    }
  }

  closeTooltip(tooltip: NgbTooltip) {
    if (tooltip.isOpen()) {
      tooltip.close();
    }
  }
}
