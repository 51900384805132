import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
  ChangeDetectorRef,
} from '@angular/core';
import { VisualNumberModel } from '@shared/models/widgets';
import { CmsWidgetSettingsModel } from '@shared/models';

@Component({
  selector: 'pr-visual-number',
  templateUrl: './visual-number.component.html',
  styleUrls: ['./visual-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VisualNumberComponent implements OnInit {
  @Input() settings: CmsWidgetSettingsModel;
  @HostBinding('style.height.px')
  get height() {
    return this.settings?.chartHeight - (this.settings.marginBottom ?? 0);
  }
  // tslint:disable-next-line:variable-name
  private _data: VisualNumberModel;
  @Input() set data(data: VisualNumberModel) {
    this._data = data;

    const value = parseFloat(this._data.data.value);
    if (this.settings.animations && !isNaN(value)) {
      this.animateValue(value * 0.9, value, 1000,
        (Math.floor(parseFloat(this._data.data.value)) === parseFloat(this._data.data.value))
      );
    } else {
      this.value = value;
    }
  }
  get data(): VisualNumberModel {
    return this._data;
  }

  value: number;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {}

  formatNumber(num: number) {
    const integerPart = Math.floor(num);
    if (num === integerPart) {
      return integerPart.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
    }
    else {
      const decimalPart = (num - integerPart)
        .toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 1
        })
        .substring(2);
      return integerPart.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') + ',' + decimalPart;
    }
  }

  private animateValue(start: number, end: number, duration: number, roundNumber: boolean) {
    // assumes integer values for start and end
    const range = end - start;
    // no timer shorter than 50ms (not really visible any way)
    const minTimer = 50;
    // calc step time to show all interediate values
    let stepTime = Math.abs(Math.floor(duration / range));

    // never go below minTimer
    stepTime = Math.max(stepTime, minTimer);

    // get current time and calculate desired end time
    const startTime = new Date().getTime();
    const endTime = startTime + duration;
    let timer;

    const that = this;
    const run = () => {
      const now = new Date().getTime();
      const remaining = Math.max((endTime - now) / duration, 0);
      const value = roundNumber ? Math.round(end - remaining * range) : Math.round((end - remaining * range ) * 10) / 10;
      that.value = value;
      that.cdr.markForCheck();
      if (value === end) {
        clearInterval(timer);
      }
    };

    timer = setInterval(run, stepTime);
    run();
  }
}
