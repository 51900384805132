import {
  Directive,
  ChangeDetectorRef,
  AfterViewInit,
  ElementRef,
  HostBinding,
  Input,
} from '@angular/core';
import { fromEvent, merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@shared/components';
import { Router } from '@angular/router';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Directive({
  selector: '[prShowTooltip]',
})
export class ShowTooltipDirective extends BaseComponent
  implements AfterViewInit {
  // tslint:disable-next-line: no-input-rename
  private _showTooltip = false;
  @Input('prShowTooltip') set showTooltip(showTooltip: boolean) {
    this._showTooltip = showTooltip;
    this.showTooltipFce();
  }
  get showTooltip() {
    return this._showTooltip;
  }
  // tslint:disable-next-line: no-input-rename
  @Input('prShowTooltipTitle') prShowTooltipTitle: string;
  // tslint:disable-next-line: no-input-rename
  @Input('prShowTooltipDescription') prShowTooltipDescription: string;
  // tslint:disable-next-line: no-input-rename
  @Input('prShowTooltipColor') prShowTooltipColor: string;
  // tslint:disable-next-line: no-input-rename
  @Input('prShowTooltipInstance') prShowTooltipInstance: NgbTooltip;

  constructor() {
    super();
  }

  ngAfterViewInit() {
    this.showTooltipFce();
  }

  private showTooltipFce() {
    if (this.showTooltip) {
      this.prShowTooltipInstance?.open({
        title: this.prShowTooltipTitle,
        description: this.prShowTooltipDescription,
        color: this.prShowTooltipColor,
      });
    } else {
      this.prShowTooltipInstance?.close();
    }
  }
}
