import {
  Component,
  OnInit,
  Input,
  ElementRef,
  AfterViewInit,
  Renderer2,
} from '@angular/core';
import { WidgetModel, ChartDataModel } from '@shared/models/widgets';
import { CmsWidgetSettingsModel } from '@shared/models';
import {
  ColorHelper,
} from '@swimlane/ngx-charts';
import { WidgetWithLegendComponent } from '../base';

@Component({
  selector: 'pr-chart-line-detail',
  templateUrl: './chart-line-detail.component.html',
  styleUrls: ['./chart-line-detail.component.scss'],
})
export class ChartLineDetailComponent extends WidgetWithLegendComponent
  implements OnInit, AfterViewInit {
  private _data: WidgetModel<ChartDataModel>;
  @Input() settings: CmsWidgetSettingsModel;
  @Input() detailTitle: string;
  @Input() set data(data: WidgetModel<ChartDataModel>) {
    this._data = data;
    this.legendData = data.data.series.map((d) => d.name);
    this.legendColors = new ColorHelper(
      this.settings.colorScheme,
      'ordinal',
      this.legendData,
      null
    );
  }
  get data(): WidgetModel<ChartDataModel> {
    return this._data;
  }
  legendData;
  legendColors;
  view: any[] = undefined;

  // options
  legend: boolean = true;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  timeline: boolean = false;
  showTitle = true;

  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5'],
  };

  chartNames: string[];
  colors: ColorHelper;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2) {
      super();
  }

  ngOnInit() {
    this.chartNames = this.data.data.series.map((d) => d.name);
    this.colors = new ColorHelper(
      this.settings.colorScheme,
      'ordinal',
      this.chartNames,
      this.settings.colorScheme
    );
  }

  ngAfterViewInit() {}

  onSelect(data): void { }

  onActivate(data): void { }

  onDeactivate(data): void {}

  legendSelect(data): void { }

  legendActivate(data) {
  }

  legendDeactivate(data) {
  }
}
